import {useSelector} from "react-redux";
import {Field, Form} from "react-final-form";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Input, Label, Select, Textarea} from "../../components/Form";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import SmartSelect from "react-select";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";

const Grid = styled.div`
    padding: 10px;
    background-color: #F0F0F0;
    margin: 8px;
`
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 160px 240px;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-start;
`;

const StyledButton = styled(Button)`
    width: 25%;
`;

const StyledSmartSelect = styled(SmartSelect)`
    div:first-child {
        border-radius: 20px;
        border: none;
    }
`;

export const PersonalTasksForm = ({update, closeModal}) => {
    const token = useSelector((store) => store.token);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [showTopic, setShowTopic] = useState(false);
    const [showTopicElements, setShowTopicElements] = useState(false);
    const [topicElements, setTopicElements] = useState(null);
    const [userId, setUserId] = useState(null);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        axios.get(`${apiUrl}/personal/tasks`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {clients: newClients, users: newUsers, user_id: newUserId}}) => {
            setClients(newClients);
            setUsers(newUsers);
            setUserId(newUserId);
        })
    }, [token])

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalFiles) {
            return;
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const updateTopicElements = (value, values) => {
        if (!values.client_id.value) {
            return;
        }

        if (value === "policy") {
            loadClientPolicies(values)
            setShowTopicElements(true);
        } else if (value === "vehicle") {
            loadClientVehicles(values)
            setShowTopicElements(true);
        }
    }

    const loadClientVehicles = (values) => {
        axios.get(`${apiUrl}/personal/tasks/vehicles/${values.client_id.value}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: clientVehicles}}) => {
            setTopicElements(clientVehicles);
        })
    }

    const loadClientPolicies = (values) => {
        axios.get(`${apiUrl}/personal/tasks/policies/${values.client_id.value}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {policies: clientPolicies}}) => {
            setTopicElements(clientPolicies);
        })
    }

    return (
        <>
            <Grid>
                <Form
                    initialValues={{user_id: userId}}
                    onSubmit={values => {
                        let dataToSent = values;
                        if (dataToSent.client_id) {
                            dataToSent.client_id = dataToSent.client_id.value
                        }
                        if (dataToSent.topic_element) {
                            dataToSent.topic_element = dataToSent.topic_element.value
                        }
                        const formData = new FormData();
                        Object.keys(dataToSent).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        })
                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/personal/tasks`, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Dane zostały zapisane.");
                            update();
                            closeModal(null);
                        }).catch(() => {
                            toast.error("Uzupełnij dane");
                        });
                    }}
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        ...arrayMutators
                    }}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <Field
                                name='description'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Opis</Label>
                                            <Textarea name="" id={input.name} rows="4" {...input}/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='date'
                                type="date"
                                parse={value => (value === "" ? null : value)}
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label>Data zadania</Label>
                                            <Input {...input} id={input.name} placeholder=""/>
                                        </InputWrapper>
                                    </>
                                )}/>

                            <Field
                                name='client_id'
                                render={({input, meta}) => (
                                    <>
                                        <InputWrapper>
                                            <Label htmlFor={input.name}>Klienci</Label>
                                            <StyledSmartSelect {...input} id={input.name}
                                                               searchable
                                                               placeholder="Powiązani klienci"
                                                               noOptionsMessage={() => 'Brak opcji'}
                                                               options={clients}
                                                               onChange={(e) => {
                                                                   input.onChange(e);
                                                                   setShowTopic(true);
                                                               }}
                                            />
                                        </InputWrapper>
                                    </>
                                )}
                            />
                            {showTopic && (
                                <>
                                    <Field
                                        name='topic'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Temat</Label>
                                                    <Select {...input} id={input.name} onChange={(e) => {
                                                        input.onChange(e);

                                                        if (e.target.value === "Brak") {
                                                            setShowTopicElements(false);
                                                            setTopicElements(null);
                                                        } else {
                                                            updateTopicElements(e.target.value, values);
                                                        }
                                                    }}>
                                                        <option value="nd">Brak</option>
                                                        <option value="policy">Polisa</option>
                                                        <option value="vehicle">Samochód</option>
                                                    </Select>
                                                </InputWrapper>
                                            </>
                                        )}
                                    />
                                </>
                            )}
                            {showTopicElements && (
                                <>
                                    <Field
                                        name='topic_element'
                                        render={({input, meta}) => (
                                            <>
                                                <InputWrapper>
                                                    <Label htmlFor={input.name}>Element</Label>
                                                    <StyledSmartSelect {...input} id={input.name}
                                                                       searchable
                                                                       placeholder="Wybierz"
                                                                       noOptionsMessage={() => 'Brak opcji'}
                                                                       options={topicElements}
                                                    />
                                                </InputWrapper>
                                            </>
                                        )}
                                    />
                                </>
                            )}
                            <Files name={`files`} index={0} setFilesToForm={setFilesToForm} label={`Załączniki`}/>
                            <StyledButton>Zapisz</StyledButton>
                        </StyledForm>
                    )}
                />
            </Grid>
        </>
    );
}
