import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {VehicleForm} from "./VehicleForm";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {createFormData} from "../../helpers/form";

const H1 = styled.h1`
margin-bottom: 10px;
`;

export const VehicleCreate = () => {
    const [redirect, setRedirect] = useState(null);
    const token = useSelector((store) => store.token);

    return (
        <>
            {redirect && <Redirect to={redirect}/>}
            <PanelContentWrapper>
                <H1>Dodaj pojazd</H1>
                <VehicleForm withClient={false} initialValues={{}}
                             onSubmit={(values, newRegistrationNumberScanFile,
                                        invoiceScanFile, leasingScanFile, protocolFile, globalPhotos, globalFiles) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        formData.append(key, values[key] ?? '')
                    });
                    if (newRegistrationNumberScanFile) {
                        formData.append('newRegistrationNumberScan', newRegistrationNumberScanFile);
                    }
                    if (invoiceScanFile) {
                        formData.append('invoiceScan', invoiceScanFile);
                    }
                    if (leasingScanFile) {
                        formData.append('leasingScan', leasingScanFile);
                    }
                    if (protocolFile) {
                        formData.append('protocol', protocolFile);
                    }
                    createFormData(formData, 'photos', values.photos);
                    if (globalPhotos.length > 0) {
                        globalPhotos.forEach(({photos}) => {
                            photos.forEach((photo) => {
                                formData.append(`photos[${photo.index}][file]`, photo.file);
                            })
                        });
                    }
                    createFormData(formData, 'files', values.files);
                    if (globalFiles.length > 0) {
                        globalFiles.forEach(({files}) => {
                            files.forEach((file) => {
                                formData.append(`files[${file.index}][file]`, file.file);
                            })
                        });
                    }

                    axios.post(`${apiUrl}/vehicle`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(({data: {vehicle: {id}}}) => {
                        toast.success("Pojazd został dodany do bazy.");
                        setRedirect(`/pojazdy/${id}`)
                    }).catch(e => {
                        console.log(e.response);
                        toast.error("Coś poszło nie tak.");
                    });
                }}/>
            </PanelContentWrapper>
        </>
    );
};
