import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Field, Form} from "react-final-form";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Input, Label} from "../../components/Form";
import {File} from "../../components/File";
import {Button} from "../../components/Buttons";

const StatusWrapper = styled.div`
    margin-bottom: 40px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled(Label)`
    width: 160px;
    margin: 0.75rem 0;
    display: inline-block;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    margin: 10px 0;
`;

const StyledDivError = styled.div`
    color: red;
`;

export const ArchiveProtocol = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [protocolFile, setProtocolFile] = useState(null);
    const [protocol, setProtocol] = useState(null);
    const [protocolDate, setProtocolDate] = useState(null);

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDo/${toDoId}/archive/protocolFields`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             protocolDate,
                             protocol
                         }
                     }) => {
                setProtocolDate(protocolDate)
                setProtocol(protocol);
            });
        }
    }, [token, toDoId]);

    return (
        <StatusWrapper>
            <Form
                onSubmit={values => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        formData.append(key, values[key] ?? '')
                    });

                    if (protocolFile) {
                        formData.append('protocol', protocolFile);
                    }

                    axios.post(`${apiUrl}/toDo/${toDoId}/archiveProtocol`, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(() => {
                        toast.success("Twoje dane zostały zapisane.");
                    }).catch(() => {
                        toast.error("Coś poszło nie tak.");
                    });
                }}
                initialValues={{protocol: protocol, protocolDate: protocolDate}}
                validate={values => {
                    const errors = {}
                    if (!values.protocolDate) {
                        errors.protocolDate = 'Data jest wymagana'
                    }
                    if (!values.protocol_path && !protocol) {
                        errors.protocol_path = 'Plik jest wymagany'
                    }
                    return errors;
                }}
                render={({handleSubmit, form, values}) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <div>
                            <h2 style={{marginBottom: "1rem"}}>Protokół zdawczo-odbiorczy</h2>
                        </div>
                        <Field
                            name={`protocolDate`}
                            type="date"
                            parse={value => (value === "" ? null : value)}
                            render={({input, meta}) => (
                                <>
                                    <div>
                                        <StyledLabel>Data</StyledLabel>
                                        <Input
                                            {...input}
                                            id={input.name}
                                            placeholder=""
                                            onChange={(e) => {
                                                input.onChange(e);
                                            }}
                                        />
                                        {meta.error && meta.touched && <StyledDivError>{meta.error}</StyledDivError>}
                                    </div>
                                </>
                            )}/>
                        <File
                            name="protocol_path"
                            label="Plik"
                            onFileSelect={(file) => setProtocolFile(file)}
                            initialValue={values.protocol}
                        />

                        {!archived && (
                            <ButtonsWrapper>
                                <Button type="submit" small smallText>ZAPISZ</Button>
                            </ButtonsWrapper>

                        )}
                    </StyledForm>
                )}
            />
        </StatusWrapper>
    );
}
