import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button} from "../components/Buttons";
import PanelContentWrapper from "../components/PanelContentWrapper";
import axios from "axios";
import {apiUrl} from "./api";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "../components/Table";
import {StyledFontAwesomeIcon, StyledLink} from "../components/ListComponents";
import {faArrowRight, faCheck, faTimes, faPlus, faBell} from "@fortawesome/free-solid-svg-icons";
import {Pagination} from "@material-ui/lab";
import {Modal} from "../components/Modal";
import {ModalButtonWrapper, P} from "../components/Modals";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PersonalTasksForm} from "./PersonalTasks/PersonalTasksForm";
import {Link, useHistory} from "react-router-dom";
import {NEW_TO_DO} from "../actions";

const Container = styled.div`
  overflow: scroll;
  height: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 45px;
  margin: 30px 40px;
`;

const StyledPanelContentWrapper = styled(PanelContentWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.4rem;
  color: ${({theme: {colors: {darkerGray}}}) => darkerGray};
  font-weight: 300;
  padding: 20px 30px;
  margin: 0;
`;

const Count = styled.span`
  color: ${({theme: {colors: {red}}}) => red};
  font-size: 3.6rem;
  font-weight: 700;
`;


const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: ${({theme: {colors: {red}}}) => red};;
  font-weight: 900;
  font-size: 1.6rem;
  top: 5px;
  right: 5px;
`;

const StyledSmallFontAwesomeIcon = styled(StyledFontAwesomeIcon)`
  color: ${({theme: {colors: {red}}}) => red};
  font-size: 1rem;
`;

const AddButton = styled(StyledButton)`
  display: flex;
  margin-left: 20px;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

const TableTitle = styled.h2`
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Dashboard = () => {
    const history = useHistory();
    const token = useSelector((store) => store.token);
    const [toDoTasks, setToDoTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    const [personalTasks, setPersonalTasks] = useState([]);
    const [p, setP] = useState(1);
    const [ps, setPs] = useState(1);

    const [autoPersonalTasks, setAutoPersonalTasks] = useState([]);
    const [autoP, setAutoP] = useState(1);
    const [autoPs, setAutoPs] = useState(1);

    const [toDoMissingTasks, setToDoMissingTasks] = useState([]);
    const [pM, setPM] = useState(1);
    const [pMs, setPMs] = useState(1);

    const [toDoTaskModal, setToDoTaskModal] = useState(null);
    const [personalTaskModal, setPersonalTaskModal] = useState(null);
    const [addTaskModal, setAddTaskModal] = useState(null);
    const [change, setChange] = useState(1);

    const [countNewTasks, setCountNewTasks] = useState(0);
    const [countInProgressTasks, setCountInProgressTasks] = useState(0);
    const dispatch = useDispatch();

    const update = () => {
        setChange(change + 1);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/dashboard`, {
            params: {
                page,
                p,
                pM,
                autoP
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({
                     data: {
                         toDoTasks: newToDoTasks,
                         lastPageToDoTasks: lastPage,
                         personalTasks: newPersonalTasks,
                         lastPagePersonalTasks: newLastPagePersonalTasks,
                         personalAutoTasks: newPersonalAutoTasks,
                         // eslint-disable-next-line
                         lastPageAutoPersonalTasks: lastPageAutoPersonalTasks,
                         countNew,
                         countInProgress,
                         countToDoWithNewMessages,
                         toDoMissingTasks: newToDoMissingTasks,
                         lastToDoMissingTasks: newLastToDoMissingTasks
                     }
                 }) => {
            setToDoTasks(newToDoTasks);
            setPersonalTasks(newPersonalTasks);
            setPages(lastPage);
            setPs(newLastPagePersonalTasks);
            setCountNewTasks(countNew);
            setCountInProgressTasks(countInProgress);
            setToDoMissingTasks(newToDoMissingTasks);
            setPMs(newLastToDoMissingTasks);
            dispatch({
                type: NEW_TO_DO,
                payload: {newToDo: countToDoWithNewMessages}
            });

            setAutoPersonalTasks(newPersonalAutoTasks);
            setAutoPs(lastPageAutoPersonalTasks);
        })
        // eslint-disable-next-line
    }, [token, page, change, p, pM, autoP])

    return (
        <>
            <Container>
                <Wrapper>
                    <StyledPanelContentWrapper>
                        <div>
                            <p>Otwarte<br/>sprawy</p>
                            <Count>{countInProgressTasks}</Count>
                        </div>
                        <Link to={'/taski/1'}>
                            <Button>Zobacz</Button>
                        </Link>
                    </StyledPanelContentWrapper>
                    <StyledPanelContentWrapper>
                        <div>
                            <p>Nowe<br/>zadania</p>
                            <Count>{countNewTasks}</Count>
                        </div>
                        <Link to={'/taski/0'}>
                            <Button>Zobacz</Button>
                        </Link>
                    </StyledPanelContentWrapper>
                    <ActionWrapper>
                        <Link to={'/taski/2'}>
                            <Button>Archiwum</Button>
                        </Link>

                        <AddButton onClick={() => {
                            setAddTaskModal(true);
                        }}>
                            <FontAwesomeIcon icon={faPlus} color="red" size="2x"/> Dodaj zadanie
                        </AddButton>
                    </ActionWrapper>
                    <div></div>
                    <div>
                        <TableTitle>Zadania customowe</TableTitle>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Status</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {personalTasks.map(({
                                                    id,
                                                    client_id: clientId,
                                                    clientName,
                                                    userName,
                                                    description,
                                                    date,
                                                    status,
                                                    statusName,
                                                    commentsCount
                                                }, key) => (
                                <tr key={key} style={{
                                    backgroundColor: status === 0 ? '#F0F0F0' : 'inherit',
                                    fontWeight: status === 0 ? 'bold' : 'inherit'
                                }}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>
                                        {(status === 0) ? (
                                            <div>
                                                <StyledSmallFontAwesomeIcon icon={faBell} fixedWidth/>
                                                <span style={{color: 'red'}}> {statusName ?? '-'}</span>
                                            </div>
                                        ) : (
                                            <span>{statusName ?? '-'}</span>
                                        )}
                                    </td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {(status === 0 || status === 1) ? (
                                            <StyledButton onClick={() => {
                                                setPersonalTaskModal(id);
                                            }}>
                                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                            </StyledButton>
                                        ) : (
                                            <StyledButton>
                                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                            </StyledButton>
                                        )}
                                        <StyledLink to={`/taski/osobiste/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={ps} page={p} shape="rounded" size="large" onChange={(event, page) => {
                            setP(page);
                        }}/>
                    </div>
                    <div>
                        <TableTitle>Zadania TODO</TableTitle>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Status</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {toDoTasks.map(({
                                                id,
                                                clientName,
                                                description,
                                                date,
                                                status,
                                                to_do_id: toDoId,
                                                userName,
                                                statusName,
                                                commentsCount
                                            }, key) => (
                                <tr key={key} style={{
                                    backgroundColor: status === 0 ? '#F0F0F0' : 'inherit',
                                    fontWeight: status === 0 ? 'bold' : 'inherit'
                                }}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>
                                        {(status === 0) ? (
                                            <div>
                                                <StyledSmallFontAwesomeIcon icon={faBell} fixedWidth/>
                                                <span style={{color: 'red'}}> {statusName ?? '-'}</span>
                                            </div>
                                        ) : (
                                            <span>{statusName ?? '-'}</span>
                                        )}
                                    </td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {(status === 0 || status === 1) ? (
                                            <StyledButton onClick={() => {
                                                setToDoTaskModal(id);
                                            }}>
                                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                            </StyledButton>
                                        ) : (
                                            <StyledButton>
                                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                            </StyledButton>
                                        )}
                                        <StyledLink to={`/taski/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={pages} page={page} shape="rounded" size="large" onChange={(event, page) => {
                            setPage(page);
                        }}/>
                    </div>
                    <div>
                        <TableTitle>Braki TODO</TableTitle>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Status</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {toDoMissingTasks.map(({
                                                       id,
                                                       clientName,
                                                       description,
                                                       date,
                                                       status,
                                                       to_do_id: toDoId,
                                                       userName,
                                                       statusName,
                                                       commentsCount
                                                   }, key) => (
                                <tr key={key} style={{
                                    backgroundColor: status === 0 ? '#F0F0F0' : 'inherit',
                                    fontWeight: status === 0 ? 'bold' : 'inherit'
                                }}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>
                                        {(status === 0) ? (
                                            <div>
                                                <StyledSmallFontAwesomeIcon icon={faBell} fixedWidth/>
                                                <span style={{color: 'red'}}> {statusName ?? '-'}</span>
                                            </div>
                                        ) : (
                                            <span>{statusName ?? '-'}</span>
                                        )}
                                    </td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {(status === 0 || status === 1) ? (
                                            <StyledButton onClick={() => {
                                                setToDoTaskModal(id);
                                            }}>
                                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                            </StyledButton>
                                        ) : (
                                            <StyledButton>
                                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                            </StyledButton>
                                        )}
                                        <StyledLink to={`/taski/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={pMs} page={pM} shape="rounded" size="large" onChange={(event, page) => {
                            setPM(page);
                        }}/>
                    </div>


                    <div>
                        <TableTitle>Zadania automatyczne</TableTitle>
                        <Table>
                            <thead>
                            <tr>
                                <th>Klient</th>
                                <th>Dla</th>
                                <th>Nazwa zadania</th>
                                <th>Status</th>
                                <th>Komentarze</th>
                                <th>Data</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {autoPersonalTasks.map(({
                                                        id,
                                                        client_id: clientId,
                                                        clientName,
                                                        userName,
                                                        description,
                                                        date,
                                                        status,
                                                        statusName,
                                                        commentsCount
                                                    }, key) => (
                                <tr key={key} style={{
                                    backgroundColor: status === 0 ? '#F0F0F0' : 'inherit',
                                    fontWeight: status === 0 ? 'bold' : 'inherit'
                                }}>
                                    <td>{clientName ?? '-'}</td>
                                    <td>{userName ?? '-'}</td>
                                    <td>{description}</td>
                                    <td>
                                        {(status === 0) ? (
                                            <div>
                                                <StyledSmallFontAwesomeIcon icon={faBell} fixedWidth/>
                                                <span style={{color: 'red'}}> {statusName ?? '-'}</span>
                                            </div>
                                        ) : (
                                            <span>{statusName ?? '-'}</span>
                                        )}
                                    </td>
                                    <td>{commentsCount}</td>
                                    <td>{date}</td>
                                    <td style={{display: "flex"}}>
                                        {(status === 0 || status === 1) ? (
                                            <StyledButton onClick={() => {
                                                setPersonalTaskModal(id);
                                            }}>
                                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                                            </StyledButton>
                                        ) : (
                                            <StyledButton>
                                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                                            </StyledButton>
                                        )}
                                        <StyledLink to={`/taski/osobiste/zobacz/${id}`}>
                                            <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                                        </StyledLink>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination count={autoPs} page={autoP} shape="rounded" size="large"
                                    onChange={(event, page) => {
                                        setAutoP(page);
                                    }}/>
                    </div>
                </Wrapper>
            </Container>

            {personalTaskModal && <Modal closeModal={() => {
                setPersonalTaskModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/personal/tasks/${personalTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            update();
                        }).catch(() => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setPersonalTaskModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setPersonalTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {toDoTaskModal && <Modal closeModal={() => {
                setToDoTaskModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/tasks/${toDoTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                            update();
                        }).catch(() => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setToDoTaskModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setToDoTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {addTaskModal && <Modal closeModal={() => {
                setAddTaskModal(null)
            }}>
                <PersonalTasksForm
                    update={update}
                    closeModal={setAddTaskModal}
                />
            </Modal>}
        </>
    );
};
